import Grid from "@mui/material/Unstable_Grid2";
import "../styles.css";
import {
  SectionHeading,
  Subheading,
  InfoWrapper,
  InformationMessage,
} from "../styles";
import { COLORS } from "../../../constants";

const About = () => {
  return (
    <Grid container sx={{ background: COLORS.BACKGROUND_NEUTRAL }}>
      <Grid xs={1} md={2}></Grid>
      <Grid xs={10} md={8}>
        <InfoWrapper>
          <SectionHeading>About</SectionHeading>
          <InformationMessage>
            Wednesday Nights from January 8th to March 19th 2025
            <br />
            7:00-8:30pm at Lincoln Middle School
          </InformationMessage>
          <Subheading>WHO</Subheading>
          <InformationMessage>
            Girls ages 15 and under. No prior volleyball experience needed. Will
            be covering basics for beginners and offer more practice for
            experienced players.
          </InformationMessage>
          <Subheading>COST</Subheading>
          <InformationMessage>$200</InformationMessage>
          <Subheading>HOW</Subheading>
          <InformationMessage>
            Payment can be made by e-transfer, cash or cheque by the end of January
          </InformationMessage>
        </InfoWrapper>
      </Grid>
    </Grid>
  );
};

export default About;
